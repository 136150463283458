import React from 'react'
import '../App.css'
import {Link} from 'react-scroll'
import hero from '../images/hero.png'

export default function Hero() {
  return (
    <div style={{display: 'flex', justifyContent:'center', marginTop: '-30em', marginBottom: '10em'}}>
        <div className="heroDiv">
            <img className='heroSpotlight' style={{boxShadow: "0 0 15px black"}} src={hero} alt="hero spotlight" />
            <h1>
              <div className='heroText2' style={{padding: "10px 0 8px 0"}}>Come see</div>              
              <div className='heroText1'>Blind Tiger</div>
              <div className='heroText2' style={{padding: "10px 0 25px 0"}}>on the road!</div>
              {/* <a className='heroButton' style={{textDecoration:'none'}} href='https://youtube.com/playlist?list=PL34Kos3om5E0vQz-lG3k4Q72tUf2pBgMg'>Click Here</a> */}
              {/* Link to section on page */}
              <Link activeClass="active" spy={true} smooth={true} duration={500} className="heroButton" to="tour">See Tour Dates</Link>
            </h1>
        </div>
    </div>
  )
}
