import '../App.css';
import React from 'react'
import venmoLogo from '../images/venmo.png'
import cashappLogo from '../images/cashapp.png'
import paypalLogo from '../images/paypal.png'
import logo from '../images/logo.png'

const paymentBlock = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textDecoration: 'none',
  color: 'white',
}

const paymentIcons = {
  width: '70px',
  // mixBlendMode: 'luminosity'
}

const main = {
  backgroundColor: "black",
  display: "flex",
  flexFlow: "column wrap",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "100%",
  height: "1000px",
  padding: "30px",
  overflow: "auto",
  boxShadow: "10px -10px var(--text-color)"
}


export default function HowToPay() {
  return (
    <div className='App'>
      {/* title */}
      <div style={{backgroundColor:"black", width: "100%"}}><a href="https://blindtiger.rocks/"><img alt="logo" class="navLogo" src={logo}/></a></div>
      <div className='container' style={main}>
        {/* subtitle */}
        {/* <h3>ways to pay:</h3> */}
        {/* payment options */}
        <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row', margin: '20px 0 0 0', width: "60%"}}>
          <a href="https://venmo.com/hunterredding" style={paymentBlock} target="_blank" rel="noopener noreferrer"><img style={paymentIcons} alt='venmo' src={venmoLogo}/><h3>venmo</h3></a>
          <a href="https://cash.app/$blindtigerfl" style={paymentBlock} target="_blank" rel="noopener noreferrer"><img style={paymentIcons} alt='cashapp' src={cashappLogo}/><h3>cashapp</h3></a>
          <a href="https://paypal.me/blindtigerfl" style={paymentBlock} target="_blank" rel="noopener noreferrer"><img style={paymentIcons} alt='paypal' src={paypalLogo}/><h3>paypal</h3></a>
        </div>
        {/* note about what and where you purchased */}
        <div style={{textAlign:'center', margin:'20px 0'}}>put in the notes what you're paying for and where you're purchasing,<br/>e.g. "tiger shirt Panama City!"<br/>thanks!</div>
        {/* full shop button */}
        {/* <a className='button' style={{marginTop: '20px'}} href="https://blindtigermerch.square.site/s/shop" target="_blank" rel="noopener noreferrer">check out our full online shop here!</a> */}
        {/* thank you and social links */}
        <div style={{display:'flex', justifyContent:'center', margin:'20px 0 5px 0'}}>thank you!!!</div>
        <div style={{display:'flex', justifyContent:'space-around', padding:'0 30px'}}>
          <a href='https://www.instagram.com/blindtigerfl/' className='fa fa-instagram' target="_blank" rel="noopener noreferrer"> </a>
          <a href='https://twitter.com/blindtigerfl' className='fa fa-twitter' target="_blank" rel="noopener noreferrer"> </a>
          <a href='https://www.facebook.com/blindtigerfl' className='fa fa-facebook' target="_blank" rel="noopener noreferrer"> </a>
          <a href='https://bit.ly/3fHL0rF' className='fa fa-rss' alt='email newsletter' target="_blank" rel="noopener noreferrer"> </a>
        </div>
      </div>
    </div>
  )
}
