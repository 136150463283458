import './App.css';
import {Link, Element} from 'react-scroll'
import { Routes, Route } from 'react-router-dom'
import Hero from './components/Hero';
import Nav from './components/Nav';
import Music from './components/Music';
import Tour from './components/Tour';
import Payment from './components/Payment';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path='/' element={<div>
          <Link activeClass="active" spy={true} smooth={true} duration={500} style={{zIndex: "1", color: "white", cursor: "pointer", padding: "10px", borderRadius: "10px", backgroundColor: "rgba(0,0,0,0.5)", bottom: "20px", right: "20px", position: "fixed"}} to="home">
            ↑ Back to Top
          </Link>
          <Element class="heroImg" name="home">
            <Nav />
          </Element>
          <Hero />
          <Music />
          <Tour />
        </div>}/>
        <Route path='/payment' element={<Payment/>}/>
      </Routes>
    </div>
  );
}

export default App;
