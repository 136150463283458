import React, { Component } from 'react'
import { Link } from 'react-scroll'
import '../App.css';
import logo from '../images/logo.png'

var navItems = {
  fontFamily: "Futura,Trebuchet MS,Arial,sans-serif", 
  letterSpacing: "4px", 
  textTransform: "uppercase",
  zIndex: "3",
  color: "white",
  padding: "0 15px",
  fontSize: "1em",
  cursor: "pointer",
//   height: "2em",
  textDecoration: "none"
}

var mNavItems = {
  fontFamily: "Futura,Trebuchet MS,Arial,sans-serif", 
  letterSpacing: "4px", 
  textTransform: "uppercase",
  zIndex: "3",
  color: "white",
  margin: "10px",
  fontSize: "1em",
  cursor: "pointer",
  height: "1em",
  textDecoration: "none"
}

var btn = {
  zIndex: "1",
  color: "white",
  padding: "10px 15px 0px 15px",
  fontSize: "2.5em",
  cursor: "pointer",
  height: "2em"
}

export default class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
        displayNav: false,
        classNames: ""
    }
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(state => ({
        displayNav: !state.displayNav
    }));
    const { classNames } = this.state;
    this.setState({ classNames: classNames ? "content" : "content-active" });
  }

  render() {
    return ( 
      <div style={{width: "100%", zIndex: "3", position: "fixed"}}>
        <div class="hide">
          <div style={{backgroundColor:'black', display: 'flex', alignItems:'center', justifyContent:'center'}}>
            <img alt="logo" class="navLogo" src={logo}/>
            <Link activeClass="active" spy={true} smooth={true} duration={500} style={navItems} to="home">Home</Link>
            <Link activeClass="active" spy={true} smooth={true} duration={500} style={navItems} to="music">Music</Link>
            {/* <Link activeClass="active" spy={true} smooth={true} duration={500} style={navItems} to="video">Video</Link> */}
            <Link activeClass="active" spy={true} smooth={true} duration={500} style={navItems} to="tour">Tour</Link>
            {/* <a target="_blank" rel="noopener noreferrer" href="https://blindtigermerch.square.site/s/shop" style={navItems}>Merch</a> */}
            <a target="_blank" rel="noopener noreferrer" href="https://newsletter.blindtiger.rocks" style={navItems}>Newsletter</a>
            {/* <Link activeClass="active" spy={true} smooth={true} duration={500} style={navItems} to="contact">Contact</Link> */}
          </div>
        </div>
        <div style={{textAlign: "left", backgroundColor: "black"}}>
          <div style={{display: "flex", width: "100%", justifyContent: 'space-between', alignItems: 'center'}}>
            <div className="collapsedBtn" style={btn} onClick={this.toggle.bind()}>≡</div>
            <img alt="logo" className="mobileNavLogo" src={logo}/>
            <div className="collapsedBtn" style={{color: 'black', padding: '0 15px', fontSize: '2.5em'}}>≡</div>
          </div>
          {this.state.displayNav && <div class={this.state.classNames} style={{padding: "0 0 1.2em 1em"}}>
            <Link activeClass="active" spy={true} smooth={true} duration={500} style={mNavItems} to="home">Home</Link>
            <Link activeClass="active" spy={true} smooth={true} duration={500} style={mNavItems} to="music">Music</Link>
            {/* <Link activeClass="active" spy={true} smooth={true} duration={500} style={mNavItems} to="video">Video</Link> */}
            <Link activeClass="active" spy={true} smooth={true} duration={500} style={mNavItems} to="tour">Tour</Link>
            {/* <a target="_blank" rel="noopener noreferrer" href="https://blindtigermerch.square.site/s/shop" style={mNavItems}>Merch</a> */}
            <a target="_blank" rel="noopener noreferrer" href="https://newsletter.blindtiger.rocks" style={mNavItems}>Newsletter</a>
            {/* <Link activeClass="active" spy={true} smooth={true} duration={500} style={mNavItems} to="contact">Contact</Link> */}
          </div>}
        </div>
      </div>
    )
  }
}
