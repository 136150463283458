import React from 'react'
import useScript from '../hooks/useScript';
import {Element} from 'react-scroll'
// import hero from '../images/hero.png'

export default function Tour(props) {
    useScript('https://widget.songkick.com/2215011/widget.js')
    return (
        <Element name="tour">
            <div style={{backgroundImage: 'url("https://gdurl.com/hGJK")', backgroundPosition: 'center', backgroundAttachment: 'fixed', display: "flex", justifyContent: "center", alignContent: "center"}}>
                <div style={{backgroundColor: "rgba(0, 0, 0, .8)", paddingTop: 100, paddingBottom: 70, width:'100%'}}>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <div className="container" id="tour">
                            <div style={{width: "100%", display: "flex", flexFlow: "column nowrap", justifyContent: "center", alignItems: "center"}}>
                                <h1>Tour</h1><p></p>
                                {/* <img style={{width: "80%"}} alt="ep release tour" src={hero}/> */}
                                <div style={{width: "90%"}}>
                                    <a href="https://www.songkick.com/artists/2215011" class="songkick-widget" data-theme="dark" data-track-button="on" data-detect-style="true" data-background-color="transparent" data-locale="en">Blind Tiger tour dates</a>
                                </div>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Element>
    )
}
