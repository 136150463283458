import React from 'react'
import '../App.css'
import {Element} from 'react-scroll'

export default function Music() {
  return (
    <Element name="music">
        <div className='musicDiv' style={{backgroundImage: 'url("https://gdurl.com/hGJK")', backgroundPosition: 'center', backgroundAttachment: 'fixed', display: "flex", justifyContent: "center"}}> 
            <div style={{backgroundColor: "rgba(0, 0, 0, .8)", padding: "70px 0", width: "100%"}}>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <div className="container" id="music">
                        <div style={{width: "80%", display: "flex", flexFlow: "column nowrap", justifyContent: "center", alignItems: "center"}}>
                            <h1>Music</h1><p></p>
                            <iframe title="self-titled" src="https://open.spotify.com/embed/album/434KFBXpZD9GcOi4PjP3my" width="100%" height="380" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                            <p></p><p></p>
                            <iframe title="Lock the Gate" src="https://open.spotify.com/embed/album/2K8GFA0sQhnHFAZQxvgDeF" width="100%" height="390" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                            <p></p><p></p>
                            <iframe title="Waterfall" src="https://open.spotify.com/embed/album/2QZUdZ6SubPpVqcCfxZLTd" width="100%" height="235" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </Element>
  )
}
